/* All scss variables */

// Colors
$purple: #9C61F1;
$purple-light: #E1C9FC;
$pink: #F198AA;
$pink-light: #F7C9D3;
$yellow: #F3B628;
$white: #FFFFFF;
$grey-light: #F5F0EA;
$grey: #E1DDD7;
$dark: #2E2E2E;
