// Everything that overrides Bootstrap classes

.sticky-top {
  top: 3rem !important;
  z-index: 1 !important;
}

// Font sizes
$h1-font-size: 3rem;
$h2-font-size: 2.25rem; // 36px
$h3-font-size: 2rem; // 32px
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;

$headings-font-weight: 700;

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 0.75rem,
);

// Spacing
$spacer: 0.5rem; // 8px
$spacers: (
  0: 0,
);

@for $i from 1 through 50 {
  $spacers: map-merge($spacers, ($i: ($i * $spacer)));
}

// Border radius
$border-radius-sm: .25rem; // 4px = rounded-1
$border-radius: 1rem; // 16px = rounded-2
$border-radius-lg: 1.875rem; // 30px = rounded-3
$border-radius-xl: 6.25rem; // 100px = rounded-4

// Buttons 
$btn-padding-y: 0.75rem;
$btn-padding-x: 1.25rem;

// Cards
$card-border-width: 0;
$card-spacer-y: 0;
$card-spacer-x: 1.25rem;
$card-cap-padding-y: 1.25rem;
$card-cap-padding-x: 1.25rem;
$card-cap-bg: none;

// Badges
$badge-font-size: 0.75rem;
$badge-padding-y: 0.375rem;
$badge-padding-x: 0.75rem;