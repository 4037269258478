// Sticky image on sides of viewport
.olga_sticky_img_right {
    margin-right: calc(-1 * (100vw - 1290px + 9px) / 2);
    width: calc(100vw - (100vw - 1290px + 9px) / 2);
}
@media (max-width: 1400px) {
    .olga_sticky_img_right {
        margin-right: calc(-1 * (100vw - 1110px + 9px) / 2);
        width: calc(100vw - (100vw - 1110px + 9px) / 2) !important;
    }
}
@media (max-width: 1200px) {
    .olga_sticky_img_right {
        margin-right: calc(-1 * (100vw - 930px + 9px) / 2);
        width: calc(100vw - (100vw - 930px + 9px) / 2) !important;
    }
}
@media (max-width: 992px) {
    .olga_sticky_img_right {
        margin-right: inherit;
        width: inherit !important;
    }
}

.olga_object_fit_cover {
    object-fit: cover;
}
.olga_text_underline {
    position: relative;
    display: inline-block;

    &::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -2px;
        border-bottom: 4px solid $yellow;
        border-radius: 50px;
        width: 100%;
        display: block;
    }
}

// Custom border radius for top (left, right) and bottom (left, right)
.olga_rounded_top_right_4 {
    border-top-right-radius: $border-radius-xl;
}
.olga_rounded_top_left_4 {
    border-top-left-radius: $border-radius-xl;
}
.olga_rounded_bottom_right_4 {
    border-bottom-right-radius: $border-radius-xl;
}
.olga_rounded_bottom_left_4 {
    border-bottom-left-radius: $border-radius-xl;
}