.card {
    margin-bottom: 2rem;

    .card-header {
        padding-bottom: 0;

        img {
            border-radius: $border-radius;
            margin-bottom: 1rem;
        }
    }
    .card-footer {
        padding-top: 0;

        a {
            width: 100%;
        }
    }
    .card-title {
        font-size: 1.125rem;
    }
}