.btn {
    border-radius: $border-radius;
    padding: $btn-padding-y $btn-padding-x;
    font-weight: 500 !important;
}
.btn-primary {
    background-color: $purple !important;
    border: 2px solid $purple !important;

    &:hover {
        background-color: darken($purple, 5%) !important;
        border: 2px solid darken($purple, 5%) !important;
    }
}
.btn-secondary {
    background-color: $grey-light !important;
    color: $dark !important;
    border: 2px solid $grey-light !important;

    &:hover {
        background-color: darken($grey-light, 5%) !important;
        border: 2px solid darken($grey-light, 5%) !important;
    }
}
.btn-outline-primary {
    border: 2px solid $grey !important;
    color: $dark !important;

    &:hover {
        background-color: $grey !important;
    }
}
.btn-light {
    color: $purple !important;
}