$primaryColor: #9C61F1 !default;
$primaryDarkerColor: #2E2E2E !default;

//global
$fontFamily: "DM Sans" !important;
$borderRadius: 15px !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputBorder: 2px solid #E1DDD7 !default;

//input field (e.g. inputtext, spinner, inputmask)
$inputIconColor: #9C61F1 !default;

//slider
$sliderBg: #E1C9FC !default;

:root {
    font-family: "DM Sans";
    --font-family: "DM Sans";
}