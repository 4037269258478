/* You can add global styles to this file, and also import other style files */
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/primeng/resources/primeng.min.css";
@import './assets/scss/primeng_overriden.scss';
@import "../node_modules/primeng-sass-theme/themes/lara/lara-light/purple/theme.scss";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
@import './assets/scss/bootstrap_overriden.scss';
@import './assets/scss/variables.scss';
@import './assets/scss/helpers.scss';
@import './assets/scss/form.scss';
@import './assets/scss/buttons.scss';
@import './assets/scss/card.scss';
@import './assets/scss/badge.scss';
@import '../node_modules/bootstrap/scss/bootstrap';

// Fonts
body {
	font-family: "DM Sans";
}

// Colors
.bg_purple {
	background-color: $purple;
}

.bg_pink {
	background-color: $pink;
}

.bg_grey_light {
	background-color: $grey-light;
}

.color_white {
	color: $white;
}

.color_pink {
	color: $pink;
}

.color_purple {
	color: $purple;
}
